import React, {useState} from "react"
import Grid from "@mui/material/Grid"
import {useTranslation} from "react-i18next"
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// @ts-ignore
import Container from "../../../../components/Container"
// @ts-ignore
import Pocket from "../../../../components/Pocket"
// @ts-ignore
import Button from "../../../../components/Button"
import ReviewTemplateReviseForm from './ReviewForms/ReviseForm'
import {OneTextFieldAndUploadFormFields} from '../../../../forms/OneTextFieldAndUploadForm'
import ReviewTemplateApproveForm, {AnonReviewApproveFormFields} from './ReviewForms/ApproveForm'


type FormName = 'revise' | 'approve' | null

export type ReviewTemplateReviewSectionProps = {
  reviseFormTitle: string,
  reviseSubmit: (values: OneTextFieldAndUploadFormFields) => Promise<void>,
  approveFormTitle: string,
  approveSubmit: (values: AnonReviewApproveFormFields) => Promise<void>,
  hideButtons: boolean,
  disclaimer: string | null,
}

export default function ReviewTemplateReviewSection(
  {
    reviseFormTitle,
    reviseSubmit,
    hideButtons,
    disclaimer,
    approveSubmit,
    approveFormTitle,
  }: ReviewTemplateReviewSectionProps
): JSX.Element {
  const {t} = useTranslation('private')
  const [showThisForm, setShowThisForm] = useState<FormName>(null)

  const toggleForm = (formName: FormName): void => {
    // if already open, toggle closes form
    if (formName === showThisForm) {
      setShowThisForm(null)
    } else {
      setShowThisForm(formName)
    }
  }

  return hideButtons
    ? (<></>)
    : (
      <>
        <Container removeTop removeBottom>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                color="success"
                endIcon={<CheckBoxIcon />}
                style={{ minWidth: 125 }}
                onClick={() => toggleForm('approve')}
              >
                {t("view.ChangeOrder.Summary.approve")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="warning"
                onClick={() => toggleForm('revise')}
              >
                {t("view.ChangeOrder.Summary.revise")}
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Grid container item style={{marginTop: '20px'}}>
          <Grid container item>
            <Pocket show={showThisForm === 'approve'}>
              <ReviewTemplateApproveForm
                onCancel={() => setShowThisForm(null)}
                titleText={approveFormTitle}
                submit={approveSubmit}
                disclaimer={disclaimer}
              />
            </Pocket>
          </Grid>

          <Grid container item>
            <Pocket show={showThisForm === 'revise'} style={{marginTop: '20px'}}>
              <ReviewTemplateReviseForm
                onCancel={() => setShowThisForm(null)}
                titleText={reviseFormTitle}
                submit={reviseSubmit}
              />
            </Pocket>
          </Grid>
        </Grid>
      </>
    )
}
