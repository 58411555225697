import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSnackbar} from 'notistack'

import {AnonTicketReviewDetails} from '../../../../types/anonymousReview'
import GuestTicketReviewTemplate from './ReviewTemplate'
import {
  notifyAnonTicketCostApprove,
  notifyAnonTicketCostRevise, submitAnonTicketCostApprove,
  submitAnonTicketCostRevise,
  uploadAnonTicketReviewProcessFiles, uploadAnonTicketReviewProcessSignature,
} from '../../../../api/anonymousReview'
import {hasFilesForUpload} from '../../../../util/file'
import {OneTextFieldAndUploadFormFields} from '../../../../forms/OneTextFieldAndUploadForm'
import {AnonReviewApproveFormFields} from './ReviewForms/ApproveForm'


const translationPath = 'view.Unauthenticated.Review.Ticket.CostReview'

export type GuestTicketCostReviewProps = {
  reviewDetails: AnonTicketReviewDetails,
}

export default function GuestTicketCostReview(
  {reviewDetails}: GuestTicketCostReviewProps
): JSX.Element {
  const { t } = useTranslation("private")
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [hideButtons, setHideButtons] = useState<boolean>(false)

  const reviseSubmit = async (values: OneTextFieldAndUploadFormFields): Promise<void> => {
    const arId = reviewDetails.anonymous_review.id
    try {
      const {data: trp} = await submitAnonTicketCostRevise(arId, {message: values.message})
      if (hasFilesForUpload(values.files)) {
        const uploadingKey = enqueueSnackbar(t('Frequent.uploadingFiles'), {variant: 'info'})
        const fileUploadMessage = await uploadAnonTicketReviewProcessFiles(values.files, arId, trp.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      await notifyAnonTicketCostRevise(arId, trp.id)
      setHideButtons(true)
      enqueueSnackbar(t('view.Tickets.cost_revise_success'), {variant: "success"})
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.cost_revise_fail'), {variant: "error"})
    }
  }

  const approveSubmit = async (values: AnonReviewApproveFormFields): Promise<void> => {
    const arId = reviewDetails.anonymous_review.id
    try {
      // update the ticket status
      const {data: trp} = await submitAnonTicketCostApprove(
        arId,
        values.message.length === 0 ? null : values.message
      )
      // upload files
      if (hasFilesForUpload(values.files)) {
        const uploadingKey = enqueueSnackbar(t('Frequent.uploadingFiles'), {variant: 'info'})
        const fileUploadMessage = await uploadAnonTicketReviewProcessFiles(values.files, arId, trp.id)
        closeSnackbar(uploadingKey)
        enqueueSnackbar(
          fileUploadMessage.message,
          {
            variant: fileUploadMessage.error ? 'error' : 'success',
            style: {whiteSpace: 'pre-wrap'}
          }
        )
      }
      // upload signature
      const signatureUploadMessage = await uploadAnonTicketReviewProcessSignature(values.signature, arId, trp.id)
      if (signatureUploadMessage.error) {
        enqueueSnackbar(signatureUploadMessage.message, {variant: 'error', style: {whiteSpace: 'pre-wrap'}})
      }
      // notify of the approval and create history object
      await notifyAnonTicketCostApprove(
        arId,
        trp.id,
        {last_name: values.last_name, first_name: values.first_name}
      )
      setHideButtons(true)
      enqueueSnackbar(t('view.Tickets.cost_approve_success'), {variant: "success"})
    } catch (_) {
      enqueueSnackbar(t('view.Tickets.cost_approve_fail'), {variant: "error"})
    }
  }

  return (
    <GuestTicketReviewTemplate
      reviewDetails={reviewDetails}
      title={t(`${translationPath}.title`)}
      reviseSubmit={reviseSubmit}
      reviseFormTitle={t('view.Tickets.cost_revise_title')}
      hideButtons={hideButtons}
      disclaimer={null}
      approveSubmit={approveSubmit}
      approveFormTitle={t('view.Tickets.cost_approve_title')}
    />
  )
}
