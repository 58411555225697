import React from "react"
import { makeStyles, useTheme } from "@mui/styles"
import { Theme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import AppBar from "@mui/material/AppBar"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Toolbar from "@mui/material/Toolbar"

import { drawerWidth } from "../../theme"
import Logo from "../../components/LogoPortable"


const padding = 24
const useStyles = makeStyles<Theme>((theme) => {
  return {
    currentProject: {
      color: "#999999",
      letterSpacing: "1.2px",
      textTransform: "uppercase",
      opacity: "1",
      fontSize: "12px",
    },
    drawerAligned: {
      [theme.breakpoints.up("md")]: {
        maxWidth: drawerWidth - padding,
        width: drawerWidth - padding,
      },
    },
    hide: {
      display: "none",
    },
    logoContainer: {
      zIndex: 0,
      [theme.breakpoints.up("md")]: {
        maxWidth: drawerWidth - padding - 47,
        width: drawerWidth - padding - 47,
      },
    },
    navContainer: {
      maxWidth: 47,
      width: 47,
      zIndex: 10,
    },
    projectName: {
      color: "#143366",
      fontSize: "15px",
      fontWeight: "700",
    },
    root: {
      background: theme.palette.background.paper,
      color: theme.palette.primary.dark,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    shift: {
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      position: "relative",
    },
  }
})

export default function UnauthenticatedNavBar(): JSX.Element {
  const classes = useStyles()
  const theme: Theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <AppBar
      className={classes.root}
      elevation={5}
      position="fixed"
    >
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent="space-between">
          <Grid className={classes.drawerAligned} item xs container justifyContent="flex-start">
            <Grid
              className={classes.logoContainer}
              item
              xs
              container
              alignItems="center"
              justifyContent={isMobile ? "flex-start" : "flex-end"}
            >
              <Logo companyName={''} tracFloLogo={true}/>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>

      <Divider />
    </AppBar>
  )
}
