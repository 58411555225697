import React from 'react'
import Typography from '@mui/material/Typography'
import {Grid} from '@mui/material'

import {AnonTicketReviewDetails} from '../../../../types/anonymousReview'
import InvoiceTopAttachedFiles from '../../../../components/Invoice/InvoiceTopAttachedFiles'
import InvoicePdf from '../../../../components/InvoicePdf'
import ReviewTemplateReviewSection, {ReviewTemplateReviewSectionProps} from './ReviewTemplate.ReviewSection'


export type GuestTicketReviewTemplateProps = {
  reviewDetails: AnonTicketReviewDetails,
  title: string,
} & ReviewTemplateReviewSectionProps

export default function GuestTicketReviewTemplate(
  {
    reviewDetails,
    title,
    reviseFormTitle,
    reviseSubmit,
    hideButtons,
    disclaimer,
    approveSubmit,
    approveFormTitle,
  }: GuestTicketReviewTemplateProps
): JSX.Element {
  return (
    <Grid container justifyContent={'center'} style={{marginTop: '75px'}}>
      <Grid container xs={12} md={11} spacing={2}>
        <Grid item>
          <Typography variant="h1">{title}</Typography>
        </Grid>
        <Grid container item>
          <ReviewTemplateReviewSection
            reviseFormTitle={reviseFormTitle}
            reviseSubmit={reviseSubmit}
            hideButtons={hideButtons}
            disclaimer={disclaimer}
            approveFormTitle={approveFormTitle}
            approveSubmit={approveSubmit}
          />
        </Grid>
        <InvoiceTopAttachedFiles files={reviewDetails.file_urls}/>
        <InvoicePdf invoice_url={reviewDetails.invoice_url}/>
      </Grid>
    </Grid>
  )
}
