import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'

import {getAnonymousReviewDetails} from '../../../api/anonymousReview'
import {AnonTicketReviewDetails} from '../../../types/anonymousReview'
import GuestTicketReview from './Ticket'
import UnauthenticatedNavBar from '../NavBar'


function GuestReviewInvalid(): JSX.Element {
  const { t } = useTranslation("private")
  return (
    <Grid container justifyContent={'center'} style={{marginTop: '75px'}}>
      <Grid container xs={12} md={11} spacing={2}>
        <Grid item>
          <Typography variant="h3">{t('view.Unauthenticated.Review.reviewInvalid')}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function GuestReview(): JSX.Element {
  const { ar_id } = useParams<{ar_id: string}>()
  const [reviewDetails, setReviewDetails] = useState<AnonTicketReviewDetails | null | 'invalid'>(null)

  useEffect(() => {
    if(ar_id != null && ar_id.length > 0) {
      getAnonymousReviewDetails(ar_id).then(
        (res) => {
          if (res.status === 200) {
            setReviewDetails(res.data)
          } else {
            setReviewDetails('invalid')
          }
        },
        (_) => {
          setReviewDetails('invalid')
        }
      )
    } else {
      setReviewDetails('invalid')
    }
  }, [ar_id])

  const content = reviewDetails == null
    ? (<></>)
    : reviewDetails === 'invalid'
    ? (<GuestReviewInvalid/>)
    : reviewDetails.anonymous_review.parent_type === 'ticket'
    ? (<GuestTicketReview reviewDetails={reviewDetails}/>)
    : (<GuestReviewInvalid/>)

  return (
    <>
      <UnauthenticatedNavBar/>
      {content}
    </>
  )
}
