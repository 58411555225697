import React, {useRef} from 'react'
import {Form, Formik} from 'formik'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import SignaturePad from 'react-signature-pad-wrapper'
import {Theme, useTheme} from '@mui/material'
import {useSnackbar} from 'notistack'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTranslation} from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import * as Yup from 'yup'

// @ts-ignore
import TextField from "../../../../../components/Fields/Text"
// @ts-ignore
import FormSmallContainer from "../../../../../components/FormSmallContainer"
// @ts-ignore
import Container from "../../../../../components/Container"
// @ts-ignore
import Button from "../../../../../components/Button"
// @ts-ignore
import TextAreaField from "../../../../../components/Fields/TextArea"
// @ts-ignore
import UploadMultipleField from "../../../../../components/Fields/UploadMultiple"
import {getBlobFromSignaturePad} from '../../../../../util/file'
import {stringIsValidNonWhitespace} from '../../../../../util/string'


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: "100%",
  },
  signaturePad: {
    background: theme.palette.background.paper,
    width: 300,
    height: "100%",
  },
  text: {
    "& .MuiListItemText-primary": {
      fontSize: "13px",
      fontWeight: "normal",
    },
  },
  textBlock: {
    width: "100%",
  },
  attach: {
    height: 40,
    borderRadius: 0,
    textTransform: "capitalize",
  },
  cancelButton: {
    color: "#0092D6",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overFlow: "hidden",
    outline: "0",
    height: 40,
  },
  clear: {
    color: "#143366",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overFlow: "hidden",
    outline: "0",
    height: 40,
  },
  send: {
    height: 40,
    borderRadius: "5",
  },
}))

export type AnonReviewApproveFormFields = {
  first_name: string,
  last_name: string,
  message: string,
  signature: Blob,
  files: any[],
}

function initAnonReviewApproveFormFields(): Omit<AnonReviewApproveFormFields, 'signature'> {
  return {
    first_name: '',
    last_name: '',
    message: '',
    files: [{name: "",},],
  }
}

const fileUploadLimitInMB = 10

type ReviewTemplateApproveFormProps = {
  onCancel: () => void,
  submit: (values: AnonReviewApproveFormFields) => Promise<void>,
  titleText: string,
  disclaimer: string | null,
}

export default function ReviewTemplateApproveForm(
  {onCancel, submit, titleText, disclaimer}: ReviewTemplateApproveFormProps
): JSX.Element {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar, } = useSnackbar()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")

  // Signature Pad Data
  const signaturePad: any = useRef({})
  function clear() {
    signaturePad.current.clear()
  }

  return (
    <FormSmallContainer>
      {// only show the disclaimer box if we have a disclaimer to show
        stringIsValidNonWhitespace(disclaimer)
          ? <Container
            style={{
              background: theme.palette.grey[600],
              marginBottom: theme.spacing(6),
              marginTop: -theme.spacing(6),
              padding: theme.spacing(4),
            }}
          >
            <Typography variant="h3" align={"center"} style={{ color: "white", textTransform: 'uppercase' }}>
              {disclaimer}
            </Typography>
          </Container>
          : <></>
      }
      <Formik<Omit<AnonReviewApproveFormFields, 'signature'>>
        initialValues={initAnonReviewApproveFormFields()}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().min(1).required(t("form.message.first_nameRequired")),
          last_name: Yup.string().min(1).required(t("form.message.last_nameRequired")),
        })}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          setSubmitting(true)
          if (signaturePad.current.isEmpty()) {
            enqueueSnackbar(
              t('form.message.signatureRequired'),
              {variant: 'warning'}
            )
            // aren't allowed to submit
            return
          }
          await submit({
            ...values,
            // adding signature to values
            signature: await getBlobFromSignaturePad(signaturePad)
          })
          resetForm()
          setSubmitting(false)
          onCancel()
        }}
      >
        {({
            errors,
            handleBlur,
            touched,
            values,
            setFieldValue,
            isSubmitting,
            handleChange,
            isValid,
          }) => {
          return (
            <Form>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align={isMobile ? "center" : "left"}
                    style={{ fontSize: 20 }}
                  >
                    {titleText}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    autoFocus
                    error={Boolean(errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    label="First Name*"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    label="Last Name*"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" align={isMobile ? "center" : "left"}>
                    {t("view.ChangeOrder.Approve.signature")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    style={{
                      background: "white",
                      width: 320,
                      marginBottom: 10,
                      padding: 10,
                      border: "2px solid #ddd",
                      borderRadius: 5,
                    }}
                  >
                    <SignaturePad
                      canvasProps={{ className: classes.signaturePad }}
                      // @ts-ignore
                      style={{ width: "100%" }}
                      ref={signaturePad}
                    />
                  </Box>
                  <Button color="secondary" type="reset" size="small" variant="text" onClick={clear}>
                    {t("view.ChangeOrder.Approve.clear")}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <TextAreaField
                    label={t("form.label.notes")}
                    value={values.message}
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadMultipleField
                    errors={errors}
                    label={t("form.label.uploadAttachments")}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    fileLimit={fileUploadLimitInMB}
                  />
                </Grid>
                <Grid container item xs={6} alignItems="center">
                  <Button color="secondary" variant="text" onClick={onCancel}>
                    {t("view.ChangeOrder.cancel")}
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent={isMobile ? "flex-start" : "flex-end"}
                >
                  <Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    next
                  >
                    {t("view.ChangeOrder.Summary.send")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </FormSmallContainer>
  )
}
