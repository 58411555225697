import React from "react"
import {Switch} from "react-router-dom"

//@ts-ignore
import SigninView from "../Unauthenticated/Signin"
//@ts-ignore
import RequestPasswordResetView from "../Unauthenticated/RequestPasswordReset"
//@ts-ignore
import NewPasswordView from "../Unauthenticated/NewPassword"
//@ts-ignore
import Signup from "../Unauthenticated/Signup"
import GuestReview from './Review'
import UnauthenticatedRouteGuard from "./Unauthenticated.RouteGuard"


export default function UnauthenticatedRouter() {
  return (
    <Switch>
      <UnauthenticatedRouteGuard path="/login">
        <SigninView />
      </UnauthenticatedRouteGuard>
      <UnauthenticatedRouteGuard path="/sign-up">
        <Signup />
      </UnauthenticatedRouteGuard>
      <UnauthenticatedRouteGuard exact path="/request-password-reset">
        <RequestPasswordResetView />
      </UnauthenticatedRouteGuard>
      <UnauthenticatedRouteGuard exact path="/reset-password/:token">
        <NewPasswordView />
      </UnauthenticatedRouteGuard>
      <UnauthenticatedRouteGuard exact path="/review/:ar_id">
        <GuestReview />
      </UnauthenticatedRouteGuard>
    </Switch>
  )
}
