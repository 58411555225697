import {AnonTicketReviewDetails} from '../../../../types/anonymousReview'
import GuestTicketTmReview from './TmReview'
import GuestTicketCostReview from './CostReview'


export type GuestTicketReviewProps = {
  reviewDetails: AnonTicketReviewDetails,
}

export default function GuestTicketReview(
  {reviewDetails}: GuestTicketReviewProps
): JSX.Element {

  return reviewDetails.reviewType === 'tm'
    ? (<GuestTicketTmReview reviewDetails={reviewDetails}/>)
    : reviewDetails.reviewType === 'cost'
    ? (<GuestTicketCostReview reviewDetails={reviewDetails}/>)
    : (<></>)
}
