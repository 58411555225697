import React from "react"
import {Link} from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import {useTranslation} from "react-i18next"

import Fab from "../../../../../../components/Fab"
import {CompanyType} from "../../../../../../types/company"
import {ActionCode, ChangeOrderActionCode} from "../../../../../../types/action"
import { ProjectUserRoleCode } from "../../../../../../types/userRoles"


function showThisButton(
  projectId: string,
  companyType: CompanyType,
  ticketId: string,
  ticketActionCode: ActionCode,
  projectUserRole: ProjectUserRoleCode,
  changeOrderActionCode?: ChangeOrderActionCode
): boolean {
  if(!!projectId && !!ticketId && companyType === 'trade'){
    if (changeOrderActionCode != null) {
      const showEditButtonForCoActionCodes: ChangeOrderActionCode[] = ['draft', 'revise']
      // Check if changeOrderActionCode allows ticket to be editable
      const attachedCoHasEditableStatus = showEditButtonForCoActionCodes.includes(changeOrderActionCode)
      if(!attachedCoHasEditableStatus) return false
    }

    const showCostEditButtonForActionCodes: ActionCode[] = ['tm_approve', 'cost_revise']
    return showCostEditButtonForActionCodes.includes(ticketActionCode) && projectUserRole !== "crew"
  }
  return false
}

interface TicketCostEditButtonProps {
  projectId: string,
  ticketId: string,
  companyType: CompanyType,
  ticketActionCode: ActionCode,
  projectUserRole: ProjectUserRoleCode,
  changeOrderActionCode?: ChangeOrderActionCode
}

export default function TicketCostEditButton(props: TicketCostEditButtonProps) {
  const { t } = useTranslation("private")

  return showThisButton(
    props.projectId,
    props.companyType,
    props.ticketId,
    props.ticketActionCode,
    props.projectUserRole,
    props.changeOrderActionCode
  )
    ? (
      <Fab
        color="secondary"
        // @ts-ignore
        component={Link}
        edge="end"
        size="medium"
        to={`/0/project/${props.projectId}/ticket/${props.ticketId}/edit_costs`}
        variant="extended"
      >
        <EditIcon />
        {t("view.Tickets.edit_costs")}
      </Fab>
    ) : <></>
}
