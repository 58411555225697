import React, { useRef } from "react"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import SignaturePad from "react-signature-pad-wrapper"
import { Theme, useTheme } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import {useSnackbar} from "notistack"

// @ts-ignore
import Button from "../components/Button"
// @ts-ignore
import TextAreaField from "../components/Fields/TextArea"
// @ts-ignore
import UploadMultipleField from "../components/Fields/UploadMultiple"
import {getBlobFromSignaturePad} from "../util/file"


const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: "100%",
  },
  signaturePad: {
    background: theme.palette.background.paper,
    width: 300,
    height: "100%",
  },
  text: {
    "& .MuiListItemText-primary": {
      fontSize: "13px",
      fontWeight: "normal",
    },
  },
  textBlock: {
    width: "100%",
  },
  attach: {
    height: 40,
    borderRadius: 0,
    textTransform: "capitalize",
  },
  cancelButton: {
    color: "#0092D6",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overFlow: "hidden",
    outline: "0",
    height: 40,
  },
  clear: {
    color: "#143366",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overFlow: "hidden",
    outline: "0",
    height: 40,
  },
  send: {
    height: 40,
    borderRadius: "5",
  },
}))

const fileUploadLimitInMB = 10

export interface SignatureAndTextFieldFormValues {
  message: string,
  files: any[],
  signature: Blob,
}

interface SignatureAndTextFieldFormProps {
  submitValues: (values: SignatureAndTextFieldFormValues) => void,
  formCancel: () => void,
  titleText: string,
}

export default function SignatureAndTextFieldForm(props: SignatureAndTextFieldFormProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("private")
  const { enqueueSnackbar } = useSnackbar()

  // Signature Pad Data
  const signaturePad: any = useRef({})
  function clear() {
    signaturePad.current.clear()
  }

  return (
    <Formik<{message: string, files: any[]}>
      initialValues={{
        message: "",
        files: [
          {
            name: "",
          },
        ],
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true)
        if (signaturePad.current.isEmpty()) {
          enqueueSnackbar(
            t('form.message.signatureRequired'),
            {variant: 'warning'}
          )
          // aren't allowed to submit
          return
        }
        await props.submitValues({
          ...values,
          // adding signature to values
          signature: await getBlobFromSignaturePad(signaturePad)
        })
        resetForm()
        setSubmitting(false)
      }}
    >
      {({
          errors,
          handleBlur,
          touched,
          values,
          setFieldValue,
          isSubmitting,
        }) => {
        const handleChange = (event: any) => {
          setFieldValue(event.target.name, event.target.value)
        }
        return (
          <Form>
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align={isMobile ? "center" : "left"}
                  style={{ fontSize: 20 }}
                >
                  {props.titleText}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" align={isMobile ? "center" : "left"}>
                  {t("view.ChangeOrder.Approve.signature")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  style={{
                    background: "white",
                    width: 320,
                    marginBottom: 10,
                    padding: 10,
                    border: "2px solid #ddd",
                    borderRadius: 5,
                  }}
                >
                  <SignaturePad
                    canvasProps={{ className: classes.signaturePad }}
                    // @ts-ignore
                    style={{ width: "100%" }}
                    ref={signaturePad}
                  />
                </Box>
                <Button color="secondary" type="reset" size="small" variant="text" onClick={clear}>
                  {t("view.ChangeOrder.Approve.clear")}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextAreaField
                  label={t("form.label.notes")}
                  value={values.message}
                  name="message"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadMultipleField
                  errors={errors}
                  label={t("form.label.uploadAttachments")}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  fileLimit={fileUploadLimitInMB}
                />
              </Grid>
              <Grid container item xs={6} alignItems="center">
                <Button color="secondary" variant="text" onClick={props.formCancel}>
                  {t("view.ChangeOrder.cancel")}
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent={isMobile ? "flex-start" : "flex-end"}
              >
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  next
                >
                  {t("view.ChangeOrder.Summary.send")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
