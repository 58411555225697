import { AxiosResponse } from "axios"

import api from "../libs/api"
import {AnonTicketReviewDetails} from '../types/anonymousReview'
import {TicketReviewProcess} from '../types/ticketReviewProcess'
import {uploadAnonReviewFiles, uploadAnonSignature} from './file'


export async function getAnonymousReviewDetails(
  ar_id: string
): Promise<AxiosResponse<AnonTicketReviewDetails>> {
  return api.get(`/review/${ar_id}`)
}

export async function submitAnonTicketTmRevise(
  arId: string,
  data: {message: string}
): Promise<AxiosResponse<TicketReviewProcess>> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket/submit_tm_revise`,
    data
  })
}

export async function notifyAnonTicketTmRevise(
  arId: string,
  trpId: string,
): Promise<any> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket_review_process/${trpId}/notify_tm_revise`,
  })
}

export async function uploadAnonTicketReviewProcessFiles(
  files: any[],
  ar_id: string,
  trp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadAnonReviewFiles(files, ar_id, trp_id, 'ticket_review_process')
}

export async function submitAnonTicketTmApprove(
  arId: string,
  message: string | null
): Promise<AxiosResponse<TicketReviewProcess>> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket/submit_tm_approve`,
    data: message === null ? {} : {message}
  })
}

// should match whats in API :: src/services/anonymousReviewService.ts
export type AnonSignatureData = {
  first_name: string,
  last_name: string,
}

export async function notifyAnonTicketTmApprove(
  arId: string,
  trpId: string,
  data: AnonSignatureData,
): Promise<any> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket_review_process/${trpId}/notify_tm_approve`,
    data,
  })
}

export async function uploadAnonTicketReviewProcessSignature(
  signature: Blob,
  ar_id: string,
  trp_id: string
): Promise<{error: boolean, message: string}> {
  return uploadAnonSignature(signature, ar_id, trp_id, 'ticket_review_process')
}

export async function submitAnonTicketCostRevise(
  arId: string,
  data: {message: string}
): Promise<AxiosResponse<TicketReviewProcess>> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket/submit_cost_revise`,
    data
  })
}

export async function notifyAnonTicketCostRevise(
  arId: string,
  trpId: string,
): Promise<any> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket_review_process/${trpId}/notify_cost_revise`,
  })
}

export async function submitAnonTicketCostApprove(
  arId: string,
  message: string | null
): Promise<AxiosResponse<TicketReviewProcess>> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket/submit_cost_approve`,
    data: message === null ? {} : {message}
  })
}

export async function notifyAnonTicketCostApprove(
  arId: string,
  trpId: string,
  data: AnonSignatureData,
): Promise<any> {
  return api({
    method: 'post',
    url: `/review/${arId}/ticket_review_process/${trpId}/notify_cost_approve`,
    data,
  })
}
