import imageCompression from 'browser-image-compression'

// @ts-ignore
import api from "../libs/api"
import { fileIsImage } from '../util/file'


interface CannotUploadFile {
  filename: string,
  reason: string,
}

// FROM API
export type FileParentType = 'ticket' | 'change_order' | 'letter' | 'history' | 'payment'
  | 'ticket_review_process' | 'ticket_history' | 'company' | 'change_order_review_process'
  | 'bulletin' | 'bulletin_history' | 'estimate' | 'estimate_history' | 'estimate_review_process'
  | 'bulletin_review_process' | 'daily'

function createUploadFileErrorMessage(cannotUploadFiles: CannotUploadFile[]): string {
  return `Unable to upload the following files:\n${
    cannotUploadFiles
      .map((cuf) =>
        `${cuf.filename}: ${cuf.reason}`
      )
      .join('\n')
  }`
}

async function uploadAnonReviewOrNormalFiles(
  files: any[],
  url: string,
): Promise<{error: boolean, message: string}> {
  const cannotUploadFiles: CannotUploadFile[] = []
  for (let i = 0, l = files.length; i < l; i++) {
    if (files[i] && files[i].name) {
      // If file is image then compress the file and replace it in the array.
      if(fileIsImage(files[i].name)){
        const options = {
          maxSizeMB: 0.01,
        }
        files[i] = await imageCompression(files[i], options)
      }
      const filename = files[i].name
      try {
        const data = new FormData()
        // NOTE: we need to force the filename here, otherwise blobs get a weird name
        data.append(`file`, files[i], filename)
        const fileResponse = await api({
          method: "post",
          url,
          data,
        })
        if (fileResponse.status >= 400) {
          cannotUploadFiles.push({filename, reason: fileResponse.data.toString()})
        }
      } catch (err: any) {
        cannotUploadFiles.push({filename, reason: err.message})
      }
    }
  }
  return cannotUploadFiles.length > 0
    ? {error: true, message: createUploadFileErrorMessage(cannotUploadFiles)}
    : {error: false, message: 'All files uploaded successfully'}
}

export async function uploadFiles(
  files: any[],
  project_id: string,
  parent_id: string,
  parent_type: FileParentType
): Promise<{error: boolean, message: string}> {
  return uploadAnonReviewOrNormalFiles(files, `/project/${project_id}/${parent_type}/${parent_id}/file`)
}

export async function uploadAnonReviewFiles(
  files: any[],
  ar_id: string,
  parent_id: string,
  parent_type: 'ticket_review_process'
): Promise<{error: boolean, message: string}> {
  return uploadAnonReviewOrNormalFiles(files, `/review/${ar_id}/${parent_type}/${parent_id}/file`)
}

export async function uploadAnonAndNormalSignature(
  signature: Blob,
  url: string,
): Promise<{error: boolean, message: string}> {
  const cannotUploadFiles: CannotUploadFile[] = []
  const filename = 'signature.png'
  try {
    const data = new FormData()
    data.append(`file`, signature, filename)
    const fileResponse = await api({
      method: "post",
      url,
      data,
    })
    if (fileResponse.status >= 400) {
      cannotUploadFiles.push({filename, reason: fileResponse.data.toString()})
    }
  } catch (err: any) {
    cannotUploadFiles.push({filename, reason: err.message})
  }
  return cannotUploadFiles.length > 0
    ? {error: true, message: createUploadFileErrorMessage(cannotUploadFiles)}
    : {error: false, message: 'Signature uploaded successfully'}
}

export async function uploadSignature(
  signature: Blob,
  project_id: string,
  parent_id: string,
  parent_type: FileParentType
): Promise<{error: boolean, message: string}> {
  return uploadAnonAndNormalSignature(signature, `/project/${project_id}/${parent_type}/${parent_id}/signature`)
}

export async function uploadAnonSignature(
  signature: Blob,
  ar_id: string,
  parent_id: string,
  parent_type: 'ticket_review_process',
): Promise<{error: boolean, message: string}> {
  return uploadAnonAndNormalSignature(signature, `/review/${ar_id}/${parent_type}/${parent_id}/signature`)
}

export async function uploadCompanyLogoFile(
  file: any,
  company_id: string,
): Promise<any> {
  if (file && file.name) {
    const filename = file.name
    const data = new FormData()
    // NOTE: we need to force the filename here, otherwise blobs get a weird name
    data.append(`file`, file, filename)
    return api({
      method: "patch",
      url: `/company/${company_id}/logo`,
      data,
    })
  }
  throw new Error('There is no file here.')
}
