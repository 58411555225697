import React from 'react'
import {Grid} from '@mui/material'


type InvoicePdfProps = {
  invoice_url: string,
}

export default function InvoicePdf(
  {invoice_url}: InvoicePdfProps
): JSX.Element {

  return (
    <Grid item xs={12}>
      <iframe
        style={{height: '800px', width: '1200px'}}
        src={invoice_url}
        title="invoice"
      />
    </Grid>
  )
}
