import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import {useTranslation} from "react-i18next"

// @ts-ignore
import Container from "../../../../../../components/Container"
// @ts-ignore
import Pocket from "../../../../../../components/Pocket"
// @ts-ignore
import { getCompany } from "../../../../../../store/features/companySlice"
import {ActionCode, ActionInfo} from "../../../../../../types/action"
import { ShareButton } from "./ReviewButtons/ShareButton"
import {SubmitTmOrCostButton} from "./ReviewButtons/SubmitTmOrCost"
import TmApproveRejectReviseButtons from "./ReviewButtons/TmApproveRejectRevise"
import SubmitTmOrCostForm from "./ReviewForms/SubmitTmOrCostForm"
import TmReviseForm from "./ReviewForms/TmReviseForm"
import TmRejectForm from "./ReviewForms/TmRejectForm"
import TmApproveForm from "./ReviewForms/TmApproveForm"
import CostApproveRejectReviseButtons from "./ReviewButtons/CostApproveRejectRevise"
import CostRejectForm from "./ReviewForms/CostRejectForm"
import CostReviseForm from "./ReviewForms/CostReviseForm"
import CostApproveForm from "./ReviewForms/CostApproveForm"
import { useTracFloSelector } from "../../../../../../store/useTracFloSelector"
import ShareTicketForm from "./ReviewForms/ShareTicketForm"
import {getProjectClients, ProjectClient} from "../../../../../../api/ticketReviewProcess"
import { UpdatesDropdown, UpdatesFormName } from "./UpdateButtons/UpdatesDropdown"
import TradeManualGcCostApproveForm from "./UpdateForms/TradeManualGcCostApproveForm"
import { ProjectUserRoleCode } from "../../../../../../types/userRoles"
import { Ticket } from "../../../../../../types/ticket"
import { Company } from "../../../../../../types/company"
import UpdateInvoiceInfoForm from "./UpdateForms/UpdateInvoiceInfoForm"
import GcManualCostApproveForm from './UpdateForms/GcManualCostApproveForm'


type FormName = "share" | "submit" | 'tm_approve' | 'tm_reject' | 'tm_revise'
  | 'cost_reviewed' | 'cost_reject' | 'cost_revise' | UpdatesFormName

interface TicketReviewButtonsProps {
  ticket: Ticket,
  ticketActionInfo: ActionInfo<ActionCode>,
  ticketActionChange: (new_action_id: number) => void,
  ticketInvoiceChange: (date_invoice: string | null, invoice_number: string | null) => void,
  projectId: string,
  hasAttachedCo: boolean,
  projectUserRole: ProjectUserRoleCode,
  isLicenseOwner: boolean,
}

function TicketReviewButtons(
  {
    ticket,
    ticketActionInfo,
    ticketActionChange,
    projectId,
    hasAttachedCo,
    projectUserRole,
    isLicenseOwner,
    ticketInvoiceChange
  }: TicketReviewButtonsProps
): JSX.Element {
  const {t} = useTranslation('private')
  const [showThisForm, setShowThisForm] = useState<FormName>(null)
  const company: Company = useTracFloSelector(getCompany)
  const [clients, setClients] = useState<ProjectClient[] | null>(null)

  const toggleTicketReviewForm = (formName: FormName): void => {
    // if already open, toggle closes form
    if (formName === showThisForm) {
      setShowThisForm(null)
    } else {
      setShowThisForm(formName)
    }
  }

  useEffect(() => {
    getProjectClients(projectId).then(
      (projectClients) => {
        if (!!projectClients) {
          setClients(projectClients)
        }
      }
    )
  }, [projectId, ticket.id])


  return (
    <>
      {/* Review Process Buttons */}
      <Container removeTop>
        <Grid container spacing={2}>
          <SubmitTmOrCostButton
            companyType={company.company_type}
            ticketActionCode={ticketActionInfo.code}
            ticketIncludesCosts={ticket.includes_costs}
            togglePocketForm={() => toggleTicketReviewForm('submit')}
            hasAttachedCo={hasAttachedCo}
            disabled={clients == null}
            disabledMessage={t('view.Tickets.noClientsTooltip')}
          />
          <ShareButton
            togglePocketForm={() => toggleTicketReviewForm('share')}
          />
          <TmApproveRejectReviseButtons
            toggleApproveForm={() => toggleTicketReviewForm('tm_approve')}
            toggleRejectForm={() => toggleTicketReviewForm('tm_reject')}
            toggleReviseForm={() => toggleTicketReviewForm('tm_revise')}
            companyType={company.company_type}
            ticketActionCode={ticketActionInfo.code}
            hasAttachedCo={hasAttachedCo}
          />
          <CostApproveRejectReviseButtons
            toggleApproveForm={() => toggleTicketReviewForm('cost_reviewed')}
            toggleRejectForm={() => toggleTicketReviewForm('cost_reject')}
            toggleReviseForm={() => toggleTicketReviewForm('cost_revise')}
            companyType={company.company_type}
            ticketActionCode={ticketActionInfo.code}
            hasAttachedCo={hasAttachedCo}
          />
          <UpdatesDropdown
            companyType={company.company_type}
            projectUserRole={projectUserRole}
            ticket={ticket}
            isLicenseOwner={isLicenseOwner}
            toggleForm={toggleTicketReviewForm}
            ticketActionCode={ticketActionInfo.code}
          />
        </Grid>
      </Container>

      {/* Review Process Forms */}
      <Pocket show={showThisForm === 'share'}>
        <ShareTicketForm
          projectId={projectId}
          toggleFormOpen={() => toggleTicketReviewForm("share")}
          ticket={ticket}
        />
      </Pocket>
      <Pocket show={showThisForm === 'submit'}>
        <SubmitTmOrCostForm
          toggleFormOpen={() => toggleTicketReviewForm("submit")}
          ticket={ticket}
          ticketActionInfo={ticketActionInfo}
          ticketActionChange={ticketActionChange}
          clients={clients}
        />
      </Pocket>
      <Pocket show={showThisForm === 'tm_revise'}>
        <TmReviseForm
          toggleFormOpen={() => toggleTicketReviewForm("tm_revise")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'tm_reject'}>
        <TmRejectForm
          toggleFormOpen={() => toggleTicketReviewForm("tm_reject")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'tm_approve'}>
        <TmApproveForm
          toggleFormOpen={() => toggleTicketReviewForm("tm_approve")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'cost_reject'}>
        <CostRejectForm
          toggleFormOpen={() => toggleTicketReviewForm("cost_reject")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'cost_revise'}>
        <CostReviseForm
          toggleFormOpen={() => toggleTicketReviewForm("cost_revise")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'cost_reviewed'}>
        <CostApproveForm
          toggleFormOpen={() => toggleTicketReviewForm("cost_reviewed")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'trade_manual_gc_cost_approve'}>
        <TradeManualGcCostApproveForm
          toggleFormOpen={() => toggleTicketReviewForm("trade_manual_gc_cost_approve")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'update_invoice_info'}>
        <UpdateInvoiceInfoForm
          toggleFormOpen={() => toggleTicketReviewForm("update_invoice_info")}
          ticket={ticket}
          projectId={projectId}
          ticketInvoiceChange={ticketInvoiceChange}
        />
      </Pocket>
      <Pocket show={showThisForm === 'gc_manual_cost_approve'}>
        <GcManualCostApproveForm
          toggleFormOpen={() => toggleTicketReviewForm("gc_manual_cost_approve")}
          ticketId={ticket.id}
          projectId={projectId}
          ticketActionChange={ticketActionChange}
        />
      </Pocket>
    </>
  )
}

export default TicketReviewButtons
