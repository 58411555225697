import React from 'react'

import OneTextFieldAndUploadForm, {OneTextFieldAndUploadFormFields} from '../../../../../forms/OneTextFieldAndUploadForm'
// @ts-ignore
import FormSmallContainer from "../../../../../components/FormSmallContainer"


type ReviewTemplateReviseFormProps = {
  onCancel: () => void,
  submit: (values: OneTextFieldAndUploadFormFields) => Promise<void>,
  titleText: string,
}

export default function ReviewTemplateReviseForm(
  {onCancel, submit, titleText}: ReviewTemplateReviseFormProps
): JSX.Element {
  const submitForm = async (values: OneTextFieldAndUploadFormFields): Promise<void> => {
    await submit(values)
    onCancel()
  }

  return (
    <FormSmallContainer>
      <OneTextFieldAndUploadForm
        onCancel={onCancel}
        submitForm={submitForm}
        titleText={titleText}
        textFieldRequired={true}
      />
    </FormSmallContainer>
  )
}
